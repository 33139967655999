import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getRestaurantById, updateRestaurantById } from "../../store/actions";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import { SpinnerCircular } from "spinners-react";


const EditRestaurant = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleRestaurant, loading } = useSelector((state: any) => state.restaurant);

  useEffect(() => {
    dispatch(getRestaurantById(id));
  }, []);

  const handleEditRestaurant = (values: any) => {
    dispatch(updateRestaurantById(id, values, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditRestaurant(values);
            }}
          >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Authkey"
                      label="Authkey"
                      type="text"
                      errorMessage="Authkey"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.Authkey || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="ShopName"
                      label="ShopName"
                      type="text"
                      errorMessage="ShopName"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.ShopName || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="BuildingName"
                      label="BuildingName"
                      type="text"
                      errorMessage="BuildingName"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.BuildingName || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Street"
                      label="Street"
                      type="text"
                      errorMessage="Street"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.Street || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="City"
                      label="City"
                      type="text"
                      errorMessage="Invalid City"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.City || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Country"
                      label="Country"
                      type="text"
                      errorMessage="Invalid Country"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.Country || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Tel1"
                      label="Tel1"
                      type="text"
                      errorMessage="Invalid Tel1"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.Tel1 || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="Tel2"
                      label="Tel2"
                      type="text"
                      errorMessage="Invalid Tel2"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.Tel2 || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="email"
                      type="text"
                      errorMessage="Invalid email"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.email || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="WebURL"
                      label="WebURL"
                      type="text"
                      errorMessage="Invalid WebURL"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleRestaurant?.WebURL || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Update</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditRestaurant);
