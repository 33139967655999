import React, { useEffect } from "react";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getAdminById, updateAdminByDocId } from "../../store/actions";
import { Col, Container, Row } from "reactstrap";
import { SpinnerCircular } from "spinners-react";

const EditAdmin = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const history = useHistory();

  const { singleAdmin, loading } = useSelector((state: any) => state.admin);

  useEffect(() => {
    dispatch(getAdminById(id));
  }, []);


  const handleEditAdmin = (values: any) => {
    dispatch(updateAdminByDocId(id, values, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditAdmin(values);
            }}
          >
            <Row>
             
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="name"
                    label="Name"
                    type="text"
                    errorMessage="Invalid Name"
                    validate={
                      {
                        required: { value: true },
                      }
                    }
                    value={singleAdmin?.name || ''}

                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="username"
                    label="UserName"
                    type="text"
                    errorMessage="Invalid UserName"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.username || ''}

                  />
                </div>
              </Col>
              <Col md="6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="text"
                    errorMessage="Invalid email"
                    validate={{
                      required: { value: true },
                    }}
                    value={singleAdmin?.email || ''}

                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success save-user update-btn"
                    disabled={loading}
                  >
                    {loading ? (
                      <SpinnerCircular
                        size={30}
                        thickness={150}
                        speed={150}
                        color="#36ad47"
                        secondaryColor="rgba(0, 0, 0, 0.44)"
                      />
                    ) : (
                      <>Update</>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditAdmin);
