import { call, put, takeEvery } from "redux-saga/effects";
import { LoginTypes } from "./actionTypes";
import { apiError, loginSuccess, loginFail } from "./actions";
import {LoginResponse} from '../../../interfaces/general'
import { loginAdminAsync } from '../../../helpers/backend_helper'
import jwtDecode from 'jwt-decode'

function* loginAdmin({ payload: { user, history } }: any) {
  try {
    const response: LoginResponse = yield call(loginAdminAsync, user);
    if(response.auth) {
      localStorage.setItem('accessToken', response.accessToken)
      let authUser:any = jwtDecode(response.accessToken)
      localStorage.setItem("authUser", JSON.stringify(authUser));
      yield put(loginSuccess(authUser));
      history.push(`/dashboard`);
    } else {
      yield put(loginFail(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.LOGIN_ADMIN, loginAdmin);
}

export default authSaga;
