import { combineReducers } from "redux"
import Layout from "./layout/reducer";
import login from "./auth/login/reducer";
import register from "./auth/register/reducer";

import admin from './admin/reducer';
import license from './license/reducer';
import customer from './customer/reducer'
import order from './order/reducer'
import restaurant from "./restaurant/reducer";

const rootReducer = combineReducers({
  Layout,
  login,
  register,
  admin,
  license,
  customer,
  order,
  restaurant
})

export default rootReducer
