import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row,  } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders } from "../../store/actions";


const Order = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orders, loading } = useSelector( (state: any) => state.order);
  const { currentUser } = useSelector((state: any) => state.login);
  const [orderList, setOrderList] = useState<any>([]);

  const { SearchBar } = Search;

  console.log('orders =>', orders)

  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders.length, // replace later with size(companies),
    custom: true,
  };

  const ordersListtColumns = [
    {
      dataField: "OrderNo",
      text: "OrderNo",
      sort: true,
    },
    {
      dataField: "CustomerId",
      text: "CustomerId",
      sort: true,
    },
    {
      dataField: "RestaurantId",
      text: "RestaurantId",
      sort: true,
    },
    {
      dataField: "Authkey",
      text: "Authkey",
      sort: true,
    },
    {
      dataField: "ComputerName",
      text: "ComputerName",
      sort: true,
    },
    {
      dataField: "Operator",
      text: "Operator",
      sort: true,
    },
    {
      dataField: "OrderDate",
      text: "OrderDate",
      sort: true,
    },
    {
      dataField: "PayStatus",
      text: "PayStatus",
      sort: true,
    },
    {
      dataField: "TotalAmount",
      text: "TotalAmount",
      sort: true,
    },
    {
      dataField: "ServiceCharge",
      text: "ServiceCharge",
      sort: true,
    },
    {
      dataField: "DeliveryCharge",
      text: "DeliveryCharge",
      sort: true,
    },
    {
      dataField: "Order_Type",
      text: "Order_Type",
      sort: true,
    },
    {
      dataField: "Order_Status",
      text: "Order_Status",
      sort: true,
    },
    {
      dataField: "Payment_Type",
      text: "Payment_Type ",
      sort: true,
    },
    {
      dataField: "IsYummyOrder",
      text: "IsYummyOrder ",
      sort: true,
    },
 
   
  ];

  useEffect(() => {
    dispatch(getAllOrders());
  }, []);

  useEffect(() => {
    setOrderList(orders)
  }, [orders]);

  const handleEditAdmin = (admin: any) => {
    history.push(`/admins/${admin.id}`);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Admins </title>
        </MetaTags>
        <Container fluid>
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <i className="bx bx-loader bx-spin align-middle me-2" style={{fontSize: 46}}></i>
            </div>
          ) : (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={orderList}
                          columns={ordersListtColumns}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <div className="row align-ite  ms-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <h5 className="card-title">
                                      orders List{" "}
                                        <span className="text-muted fw-normal ms-2">
                                          ({orders.length})
                                        </span>
                                      </h5>
                                    </div>
                                  </div>

                             
                                </div>
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon-search" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Order);
