import { call, put, takeEvery } from "redux-saga/effects";
import { RestaurantTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {getAllRestaurantSuccess, getRestaurantByIdSuccess, updateRestaurantByIdSuccess, storeRestaurantSuccess} from "./actions";

import { getAllRestaurantsAsync, getRestaurantByIdAsync, updateRestaurantByIdAsync, addRestaurantAsync } from "../../helpers/backend_helper";

function* getAllRestaurants() {
  try {
    const response: IResponse = yield call(getAllRestaurantsAsync);
    console.log('response =>', response)
    if(response.success) {
      yield put(getAllRestaurantSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

function* getRestaurantById({ payload: id}: any) {
  try {
    const response: IResponse = yield call(getRestaurantByIdAsync, id);
    if(response.success) {
      yield put(getRestaurantByIdSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}

function* updateRestaurantById({ payload: {id, restaurant, history} }: any) {
  try {
    const response: IResponse = yield call(updateRestaurantByIdAsync, id, restaurant);
    if(response.success) {
      yield put(updateRestaurantByIdSuccess(response.result));
      history.push('/restaurants')
    }
  } catch (error) {
    console.log(error)
  }
}

function* addRestaurant({ payload: restaurant }: any) {
  try {
    const response: IResponse = yield call(addRestaurantAsync, restaurant);
    if(response.success) {
      yield put(storeRestaurantSuccess(response.result));
    }
  } catch (error) {
    console.log(error)
  }
}

function* restaurantSaga() {
  yield takeEvery(RestaurantTypes.GET_ALL_RESTAURANTS, getAllRestaurants)
  yield takeEvery(RestaurantTypes.GET_RESTAURANT_BY_ID, getRestaurantById)
  yield takeEvery(RestaurantTypes.UPDATE_RESTAURANT_BY_DOCID, updateRestaurantById);
  yield takeEvery(RestaurantTypes.ADD_RESTAURANT, addRestaurant)
}

export default restaurantSaga;


