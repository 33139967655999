import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, useParams, Link, useHistory } from "react-router-dom";
import ReactDrawer from "react-drawer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useSelector, useDispatch } from "react-redux";
import { getAdminById, updateAdminByDocId } from "../../store/actions";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import Icon from "@ailibs/feather-react-ts";
import { SpinnerCircular } from "spinners-react";


const EditCustomer = (props: any) => {
  const dispatch = useDispatch();
  const { docid } = useParams<any>();
  const history = useHistory();

  const { singleAdmin, loading } = useSelector((state: any) => state.admin);

  useEffect(() => {
    dispatch(getAdminById(docid));
  }, []);

  const handleEditAdmin = (values: any) => {
    dispatch(updateAdminByDocId(docid, values, history));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div style={{ paddingRight: 10 }}>
                          <Link to="/admins" className="has-arrow">
                            <Icon name="arrow-left" />
                          </Link>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              Title: Admin Detail
                            </h5>
                            <p className="text-muted font-size-13">
                              Description: AdminID: {singleAdmin?.id}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleEditAdmin(values);
            }}
          >
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="siteCode"
                      label="Site Code"
                      placeholder="site code should 7 digits"
                      type="number"
                      errorMessage="Should be 7 digits"
                      validate={{
                        required: { value: true },
                        minLength: {
                          value: 7,
                          errorMessage: "Min 7 chars.",
                        },
                        maxLength: {
                          value: 7,
                          errorMessage: "Max 7 chars.",
                        },
                      }}
                      value={singleAdmin?.siteCode || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="userName"
                      label="User Name"
                      type="text"
                      errorMessage="Invalid Name"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleAdmin?.userName || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="firstName"
                      label="First Name"
                      type="text"
                      errorMessage="Invalid Name"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleAdmin?.firstName || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="lastName"
                      label="Last Name"
                      type="text"
                      errorMessage="Invalid Name"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleAdmin?.lastName || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="phone"
                      label="Phone"
                      type="text"
                      errorMessage="Invalid Phone number"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleAdmin?.phone || ""}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      type="email"
                      errorMessage="Invalid Email"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleAdmin?.email || ""}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <AvField
                      name="pincode"
                      label="PinCode"
                      type="text"
                      errorMessage="Invalid Pincode"
                      validate={{
                        required: { value: true },
                      }}
                      value={singleAdmin?.pincode || ""}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button type="submit" className="btn btn-success save-user update-btn" disabled={loading}>
                      {loading ? (
                        <SpinnerCircular
                          size={30}
                          thickness={150}
                          speed={150}
                          color="#36ad47"
                          secondaryColor="rgba(0, 0, 0, 0.44)"
                        />
                      ) : (
                        <>Update</>
                      )}
                    </button>
                  </div>
                </Col>
              </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EditCustomer);
