import { post, get, put, del } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//admin login
const loginAdminAsync = (data : any) => post(url.ADMIN_LOGIN, data)

//admins
const getAllAdminsAsync = () => get(`${url.GET_ALL_ADMINS}`)
const getAdminsByCompanyCodeAsync = (companyCode: string) => get(`${url.GET_ADMINS_BY_COMPANYCODE}/${companyCode}`)
const addAdminAsync = (admin: any) => post(url.ADD_ADMIN, admin)
const getAdminByIdAsync = (id: string) => get(`${url.GET_ADMIN_BY_ID}/${id}`)
const updateAdminByDocIdAsync = (docid: string, admin: any) =>  put(`${url.UPDATE_ADMIN_BY_DOCID}/${docid}`, admin)
const deleteAdminByDocIdAsyc = (id: string) => del(`${url.DEL_ADMIN_BY_DOCID}/${id}`)

//licenses
const getAllLicensesAsync = () => get(`${url.GET_ALL_LICENSES}`)
const addLicenseAsync = (license: any) => post(url.ADD_LICENSE, license)
const getLicenseByIdAsync = (id: string) => get(`${url.GET_LICENSE_BY_ID}/${id}`)
const updateLicenseByIdAsync = (id: string, license: any) =>  put(`${url.UPDATE_LICENSE_BY_ID}/${id}`, license)
const deleteLicenseByIdAsync = (id: string) => del(`${url.DEL_LICENSE_BY_ID}/${id}`)

const getAllCustomersAsync = () => get(`${url.GET_ALL_CUSTOMERS}`)
const getAllOrdersAsync = () => get(`${url.GET_ALL_ORDERS}`)

const getAllRestaurantsAsync = () => get(`${url.GET_ALL_RESTAURANTS}`)
const getRestaurantByIdAsync = (id: string) => get(`${url.GET_RESTAURANT_BY_ID}/${id}`)
const updateRestaurantByIdAsync = (id: string, restaurant: any) =>  put(`${url.UPDATE_RESTAURANT_BY_DOCID}/${id}`, restaurant)
const addRestaurantAsync = (restaurant: any) => post(url.ADD_RESTAURANT, restaurant)




export {
  getLoggedInUser,
  isUserAuthenticated,
  loginAdminAsync,
  //admin
  getAllAdminsAsync,
  getAdminsByCompanyCodeAsync,
  addAdminAsync,
  getAdminByIdAsync,
  updateAdminByDocIdAsync,
  deleteAdminByDocIdAsyc,

  //license
  getAllLicensesAsync,
  addLicenseAsync,
  getLicenseByIdAsync,
  updateLicenseByIdAsync,
  deleteLicenseByIdAsync,

  //customers
  getAllCustomersAsync,

  //restaurants
  getAllRestaurantsAsync,
  getRestaurantByIdAsync,
  updateRestaurantByIdAsync,
  addRestaurantAsync,
  //orders
  getAllOrdersAsync
}