import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { OrderTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {getAllOrdersSuccess} from "./actions";

import { getAllOrdersAsync } from "../../helpers/backend_helper";

function* getAllOrders() {
  try {
    const response: IResponse = yield call(getAllOrdersAsync);
    console.log('xxx =>', response)
    if(response.success) {
      yield put(getAllOrdersSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

// function* getAdminById({ payload: id}: any) {
//   try {
//     const response: IResponse = yield call(getAdminByIdAsync, id);
//     if(response.success) {
//       yield put(getAdminByIdSuccess(response.result));
//     }
//   } catch (error) {
//     yield put(getAdminByIdFail(error));
//   }
// }




function* orderSaga() {
  yield takeEvery(OrderTypes.GET_ALL_ORDERS, getAllOrders)
  // yield takeEvery(AdminTypes.GET_ADMIN_BY_ID, getAdminById)
}

export default orderSaga;


