import { call, put, takeEvery } from "redux-saga/effects";
// Crypto Redux States
import { CustomerTypes } from "./actionTypes";
import { IResponse } from "../../interfaces/general";
import {getAllCustomersSuccess} from "./actions";

import { getAllCustomersAsync } from "../../helpers/backend_helper";

function* getAllCustomers() {
  try {
    const response: IResponse = yield call(getAllCustomersAsync);
    if(response.success) {
      yield put(getAllCustomersSuccess(response.result));
    } 
  } catch (error) {
    console.log(error)
  }
}

// function* getAdminById({ payload: id}: any) {
//   try {
//     const response: IResponse = yield call(getAdminByIdAsync, id);
//     if(response.success) {
//       yield put(getAdminByIdSuccess(response.result));
//     }
//   } catch (error) {
//     yield put(getAdminByIdFail(error));
//   }
// }




function* customerSaga() {
  yield takeEvery(CustomerTypes.GET_ALL_CUSTOMERS, getAllCustomers)
  // yield takeEvery(AdminTypes.GET_ADMIN_BY_ID, getAdminById)
}

export default customerSaga;


