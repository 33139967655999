export enum RestaurantTypes {
  GET_ALL_RESTAURANTS = "GET_ALL_RESTAURANTS",
  GET_ALL_RESTAURANTS_SUCCESS = 'GET_ALL_RESTAURANTS_SUCCESS',

  GET_RESTAURANT_BY_ID = 'GET_RESTAURANT_BY_ID',
  GET_RESTAURANT_BY_ID_SUCCESS = 'GET_RESTAURANT_BY_ID_SUCCESS',


  UPDATE_RESTAURANT_BY_DOCID = 'UPDATE_RESTAURANT_BY_DOCID',
  UPDATE_RESTAURANT_BY_DOCID_SUCCESS = 'UPDATE_RESTAURANT_BY_DOCID_SUCCESS',

  ADD_RESTAURANT = 'ADD_RESTAURANT',
  ADD_RESTAURANT_SUCCESS = 'ADD_RESTAURANT_SUCCESS'
}
