export const ADMIN_LOGIN = '/users/auth/login'
//admins
export const GET_ALL_ADMINS = '/users'

export const GET_ADMINS_BY_COMPANYCODE = '/admins/companyCode'
export const ADD_ADMIN = '/users/auth/signup'
export const GET_ADMIN_BY_ID = '/users'
export const UPDATE_ADMIN_BY_DOCID = '/users'
export const DEL_ADMIN_BY_DOCID = '/users'


//LICENSES
export const GET_ALL_LICENSES = '/license'
export const ADD_LICENSE = '/license/store'
export const GET_LICENSE_BY_ID = '/license'
export const UPDATE_LICENSE_BY_ID = '/license'
export const DEL_LICENSE_BY_ID = '/license'

//CUSTOMERS
export const GET_ALL_CUSTOMERS = '/customers'

//RESTAURANTS
export const GET_ALL_RESTAURANTS = '/restaurants'
export const GET_RESTAURANT_BY_ID = '/restaurants'
export const UPDATE_RESTAURANT_BY_DOCID = '/restaurants'
export const ADD_RESTAURANT = '/restaurants/store'

//Orders
export const GET_ALL_ORDERS = '/orders'