import PropTypes from "prop-types";
import React, { useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import Icon from "@ailibs/feather-react-ts";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import adminIcon from '../../assets/images/icons/admins.png'
import orderIcon from '../../assets/images/icons/order.png'
import keylockerIcon from '../../assets/images/icons/keylocker.png'
import restaurantIcon from '../../assets/images/icons/restaurant.png'




const SidebarContent = (props: any) => {
  const ref = useRef<any>();

  const { currentUser } = useSelector((state: any) => state.login);

  const activateParentDropdown = useCallback(item => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul: any = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              {currentUser && (
                <Link
                  to={`/dashboard`}
                  className=""
                >
                  <Icon name="home" />
                  <span>{props.t("Dashboard")}</span>
                </Link>
              )}
            </li>
            <li>
              {currentUser && (
                <>
                 <Link to="/admins">
                    <img src = {adminIcon} alt = "admin Icon" width={15}/>
                    <span className = "sidebar-title">{props.t("Users")}</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {currentUser && (
                <>
                 <Link to="/licenses">
                    <img src = {keylockerIcon} alt = "licenses Icon" width={15}/>
                    <span className = "sidebar-title">Licenses</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {currentUser && (
                <>
                 <Link to="/restaurants">
                    <img src = {restaurantIcon} alt = "restaurants Icon" width={15}/>
                    <span className = "sidebar-title">Restaurants</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {currentUser && (
                <>
                 <Link to="/customers">
                    <img src = {adminIcon} alt = "customerIcon" width={15}/>
                    <span className = "sidebar-title">Customers</span>
                  </Link>
                </>
              )}
            </li>
            <li>
              {currentUser && (
                <>
                 <Link to="/orders">
                    <img src = {orderIcon} alt = "customerIcon" width={15}/>
                    <span className = "sidebar-title">Orders</span>
                  </Link>
                </>
              )}
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(SidebarContent));
