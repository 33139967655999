import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";
import Admins from '../pages/Admins';
import EditAdmin from '../pages/Admins/edit-admin'
import License from '../pages/License';
import EditLicense from '../pages/License/edit-license'
import Restaurant from '../pages/Restaurant'
import EditRestaurant from '../pages/Restaurant/edit-restaurant'
import Customer from '../pages/Customer';
import EditCustomer from '../pages/Customer/edit-customer'
import Order from '../pages/Order';
import EditOrder from '../pages/Order/edit-order';


//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: "/:companyCode/profile", component: userProfile },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  //dashboard
  { path: "/dashboard", component: Dashboard },
  // this route should be at the end of all other routes
  { path: "/admins", component: Admins},
  { path: "/admins/:id", component: EditAdmin},
  { path: "/licenses", component: License},
  { path: "/licenses/:id", component: EditLicense},

  { path: "/restaurants", component: Restaurant},
  { path: "/restaurants/:id", component: EditRestaurant},
  { path: "/customers", component: Customer},
  { path: "/customers/:id", component: EditCustomer},

  { path: "/orders", component: Order},
  { path: "/orders", component: EditOrder},

];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/", exact: true,  component: () => <Redirect to="/login" /> },
  { path: "/login", component: Login },
  { path: "/:companyCode/logout", component: Logout },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
