import { all, fork } from "redux-saga/effects"
import LayoutSaga from "./layout/saga"
import adminSaga from './admin/saga'
import authSaga from './auth/login/saga'
import licenseSaga from './license/saga'
import customerSaga from "./customer/saga"
import orderSaga from "./order/saga"
import restaurantSaga from "./restaurant/saga"

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(adminSaga),
    fork(authSaga),
    fork(licenseSaga),
    fork(customerSaga),
    fork(orderSaga),
    fork(restaurantSaga)
  ])
}
